.screen-tarifs {
  &__container {
    background: $background-blue-2;
  }

  &__top {
    padding: 90px 120px 120px;
    border-bottom: 1px solid $white-low;

    @include w1440 {
      padding: 60px;
    }

    @include w1024 {
      padding: 30px;
    }

    @include w480 {
      .col {
        flex: 1;
        width: 100%;
      }
    }

    h2 {
      flex: 1;
    }

    .specialist {
      cursor: pointer;
      flex: 1;
      padding-left: 90px;

      @include w1440 {
        padding-left: 60px;
      }

      @include w1024 {
        padding-left: 30px;
      }

      &--mobile {
        @include w480 {
          display: none;
        }
      }

      

      &__img {
        width: 100px;
        height: 100px;
        flex: 0 1 100px;
        margin-right: 30px;

        @include w1440 {
          font-size: 16px;
          margin-right: 15px;
        }

        @include w1024 {
          display: none;
        }

        img {
          border-radius: 50%;
          max-width: 100%;
          width: 100%;
        }
      }

      &__text {
        font-size: 24px;
        flex: 1;

        @include w1440 {
          font-size: 16px;
        }
      }
    }
  }

  .tarif {
    min-height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include w1024 {
      min-height: 190px;
    }

    &__wrapper {
      border-right: 1px solid $white-low;
      padding: 130px 120px 90px;

      @include w1440 {
        padding: 60px;
      }

      @include w1024 {
        padding: 30px;
      }

      @include w480 {
        flex: 1;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid $white-low;
      }
    }
    
    &:last-child {
      border-right: none;
    }

    &__header {
      margin-bottom: 15px;
      h3 {
        margin-right: 25px;
      }
    }

    &__sub-header {
      font-size: 24px;
      margin-bottom: 20px;
      
      @include w1440 {
        font-size: 16px;
      }

      @include w1024 {
        margin-bottom: 10px;
      }
    }

    &__cost {
      font-size: 23px;
      font-weight: 400;
      &--number {
        font-weight: 500;
        font-size: 24px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }
}

.tarifs {
  @include w480 {
    flex-direction: column;
  }
}