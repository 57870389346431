.screen-advantages {
  &__container {
    padding: 130px 120px 120px;
    background: $background-blue-1;

    @include w1440 {
      padding: 60px;
    }

    @include w1024 {
      padding: 30px;
    }
  }

  &__row {
    margin-bottom: 100px;
    @include w1440 {
      margin-bottom: 50px;
    }

    @include w1024 {
      margin-bottom: 20px;
    }
    .col {
      padding-left: 90px;

      @include w1440 {
        padding-left: 30px;
      }
    }

    .col:first-child {
      padding-left: 0px;
    }
  }

  .advantages {
    display: flex;
    align-items: flex-start;

    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin-bottom: 100px;
      grid-gap: 0px 30px;

      @include w1366 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 20px;
        margin-bottom: 50px;
      }

      @include w1024 {
        margin-bottom: 20px;
      }

      @include w480 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 20px;
      }

      h2 {
        grid-column-start: 1;
        grid-column-end: 3;
        @include w1366 {
          grid-column-start: 1;
          grid-column-end: 4;
          margin-bottom: 30px;
        }

        @include w480 {
          grid-column-start: 1;
          grid-column-end: 2;
          margin-bottom: 0px;
        }
      }
    }

    &-item {
      &__icon {
        margin-bottom: 40px;
        @include w1440 {
          margin-bottom: 20px;
        }
      }

      &__text {
        font-size: 24px;
        margin-bottom: 15px;
        @include w1440 {
          font-size: 16px;
        }
      }

      &__subtext {
        font-size: 17px;
        line-height: 28px;
        @include w1440 {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}