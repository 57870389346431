.modal {
  max-width: 1120px;
  @include w768 {
    max-width: unset;
    width: 100%;
  }

  &-wrapper {
    display: flex;
    align-items: stretch;

    @include w768 {
      display: block;
      width: 100%;
      height: 100vh;
    }
  }

  &-header {
    position: relative;
    flex: 1;

    @include w768 {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: $blue-opacity;
    }

    &-content {
      padding: 150px 150px 150px 80px;
      position: relative;
      z-index: 2;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-logo {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    h3 {
      margin-bottom: 20px;
    }

    .text-sub {
      line-height: 24px;
    }

    .email {
      margin-top: auto;
    }
  }

  &-form {
    padding: 115px 130px;
    background: $white-text;
    width: 629px;
    flex: 0 1 629px;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__header {
      display: none;
      margin-bottom: 20px;
      color: rgba(32, 52, 86, 0.4);

      h3 {
        margin-bottom: 10px;
      }

      .text-sub {
        color: rgba(32, 52, 86, 0.4);
      }

      @include w768 {
        display: block;
      }
    }

    @include w768 {
      width: 100%;
      padding: 80px 30px;
      font-size: 14px;
      min-height: 100vh;
      background: url('../images/modals/logo-bg.svg') no-repeat right bottom;
      background-color: #FFF;
    }

    .form-input {
      width: 100%;
      min-width: 366px;
      margin-bottom: 20px;

      @include w768 {
        min-width: unset;
      }

      &.invalid {
        outline: 1px solid $invalid;
      }

      input {
        border: none;
        background: $background-input;
        height: 60px;
        width: 100%;
        color: $input-color;
        padding: 20px 30px;

        &::placeholder {
          color: $input-placeholder;
        }

        &:focus {
          outline: 1px solid $gold;
        }
      }

      textarea {
        resize: none;
        border: none;
        background: $background-input;
        height: 143px;
        width: 100%;
        color: $input-color;
        padding: 20px 30px;

        &::placeholder {
          color: $input-placeholder;
        }

        &:focus {
          outline: 1px solid $gold;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &.invlaid {
        input, textarea {
          outline: 1px solid $invalid;
        }
      }
    }

    .agreement {
      margin: 10px 0 30px;
      color: rgba(32, 52, 86, 0.4);
      line-height: 24px;

      a {
        color: rgba(32, 52, 86, 1);
      }
    }
  }

  &-close {
    margin-top: 20px;

    &__btn {
      cursor: pointer;
      margin: 0 auto;
      position: relative;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      border: 1px solid $white-low;
      transform: rotate(45deg);
      transition: .3s;

      @include w1440 {
        height: 30px;
        width: 30px;
      }

      &::before, &::after {
        content: '';
        display: block;
        width: 0.5px;
        height: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $white-low;
        transition: .3s;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      &::after {
        transform: translate(-50%, -50%);
      }

      &:hover {
        border-color: $gold;

        &::before, &::after {
          background-color: $gold;
        } 
      }
    }

    @include w768 {
      position: absolute;
      top: 20px;
      right: 30px;
      margin-top: 0px;

      &__btn {
        border-color: $gold;

        &::before, &::after {
          background-color: $gold;
        }
      }
    }
  }
}

#modal-callback {
  .modal-header {
    background: url('/images/modals/callback-bg.png') no-repeat center center;
    background-size: cover;
  }
}

#modal-consult {
  .modal-header {
    background: url('/images/modals/consult-bg.png') no-repeat center center;
    background-size: cover;
  }
}

#modal-service {
  .modal-header {
    background: url('/images/modals/service-bg.png') no-repeat center center;
    background-size: cover;
  }
}

#modal-rate {
  .modal-header {
    background: url('/images/modals/rate-bg.png') no-repeat center center;
    background-size: cover;
  }
}

#modal-rate-base, #modal-rate-full {

  .modal-wrapper {
    display: block;
    background: transparent;
    color: $white-text;
  }
  
}

#modal-success {
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }

  .text-sub {
    margin-bottom: 40px;
  }
}

.modal-table {
  &__header {
    background: $background-blue-deep;
    padding: 30px;
  }

  &__content {
    background: $background-blue-2;
    border-collapse: collapse;


    td {
      border: 1px solid $white-low;
    }

    .table__text {
      width: 70%;
      padding: 20px 30px;
      color: $white-subtext;
      @include w768 {
        padding: 10px;
        font-size: 16px;
      }
    }

    .table__price {
      color: $gold;
      font-size: 23px;
      font-weight: 400;
      width: 30%;
      white-space: nowrap;
      padding: 20px 30px;
      text-align: right;

      @include w768 {
        padding: 10px;
        font-size: 16px;
      }
    }
  }

  &__bottom {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#modal-rate-full {
  .modal-table__content {
    padding: 30px;
    color: $white-subtext;
    font-size: 20px;
    line-height: 30px;
  }
}