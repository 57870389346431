.side {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: $nav-width;
  
  padding: 60px 0 50px 40px;
  border-right: 1px solid $white-low;

  &-mobile__top {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include w1024 {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__bottom {
    font-size: 16px;
    @include w1024 {
      display: none;
    }
  }

  @include w1024 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
    padding: 20px;
    
    border: none;

    &.active {
      position: fixed;
      height: 100vh;
      bottom: 0;
      display: flex;
      flex-direction: column;

      background: $background-blue-3;
      .nav-mobile-burger__arrow {
        display: block;
      }

      .nav-mobile-burger__lines {
        display: none;
      }

      .nav-mobile {
        display: flex;
      }
    }
  }
}

.nav {
  @include w1024 {
    display: none;
  }
  &__link {
    height: 53px;
    display: flex;
    align-items: center;
    color: $white-subtext;
    font-size: 17px;
    transition: .3s;

    &.active {
      color: $white-text;
      border-right: 1px solid $gold;
    }

    &:hover {
      color: $white-text;
    }
  }

  &-mobile {
    display: none;
    flex-direction: column;
    flex: 1;

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
    }

    &__link {
      font-size: 20px;

      &.active {
        color: $gold;
      }
    }

    &__item {
      padding: 20px 0;
    }

    &-burger {
      display: none;
      cursor: pointer;

      @include w1024 {
        display: block;
      }

      .nav-mobile-burger__arrow {
        display: none;
        transform: rotate(90deg);
      }

      .nav-mobile-burger__lines {
        display: block;
      }
    }
  }
}