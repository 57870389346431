.callback {
  text-align: center;
  &__container {
    padding: 110px 120px 145px;
    position: relative;

    @include w1440 {
      padding: 60px;
    }

    &--main {
      position: relative;
      z-index: 2;
      flex-direction: column;

      .text-pinyon {
        margin-bottom: 40px;
        @include w1440 {
          margin-bottom: 20px;
        }
      }
  
      .h1 {
        font-size: 70px;
        font-weight: 500;
        margin-bottom: 60px;

        @include w1440 {
          font-size: 60px;
          margin-bottom: 30px;
        }

        @include w1024 {
          font-size: 32px;
        }
      }
    }

    &--background {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../images/background-callback.png') no-repeat center center;
      background-size: cover;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(14, 25, 41, 0.8);
      }
    }
  }
}