$gold: #EAC57E;
$white-text: #FFFFFF;
$black-text: #000000;
$white-subtext: rgba(255,255,255,0.45);
$white-low: rgba(255,255,255,0.1);
$invalid: #EA847E;
$btn-sending-background: #d6d6d6;
$btn-sending-color: #777777;

$blue-opacity: rgba(14, 25, 41, 0.8);

$background-blue-deep: #121F35;
$background-blue-1: #172640;
$background-blue-2: #1a2c49;
$background-blue-3: #203456;
$background-input: #EEF2F9;
$input-placeholder: #9CA3B0;
$input-color: #203456;

$nav-width: 235px;
