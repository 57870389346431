.screen-team {
  &__container {
    background: $background-blue-1;
    padding: 115px 120px 120px;

    @include w1440 {
      padding: 60px;
    }

    @include w1024 {
      padding: 30px;
    }
  }

  &__top {
    justify-content: space-between;
    margin-bottom: 115px;

    @include w1440 {
      margin-bottom: 50px;
    }

    @include w1024 {
      margin-bottom: 20px;
    }
  }

  &__bottom {
    @include w480 {
      justify-content: center;
    }
  }

  .swiper-wrapper {
    margin-bottom: 100px;

    @include w1440 {
      margin-bottom: 50px;
    }
  }

  .swiper-pagination {
    @include w480 {
      display: none;
    }
  }
}

.team-item {
  display: flex;
  flex-direction: column;

  &__img {
    position: relative;

    img {
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      transition: .3s;
    }
    
    &--wrapper {
      margin-bottom: 30px;
      position: relative;
      overflow: hidden;

      @include w1440 {
        margin-bottom: 15px;
      }

      &:hover {
        .team-item__tags {
          display: flex;
        }

        .team-item__img::before {
          background: $blue-opacity;
        }
      }
    }
  }

  &__main {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__tags {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    padding: 10px 10px 0 10px;
  }

  &__tag {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  &__name {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;

    @include w1440 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }

  &__text {
    font-size: 17px;
    line-height: 28px;

    @include w1440 {
      font-size: 13px;
      line-height: 20px;
    }
  }
}