.screen-main {
  height: 100vh;
  text-align: center;

  &--background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: url('../images/background-lex-main-2.png') no-repeat center center;
    background-size: cover;

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background: rgba(14, 25, 41, 0.8);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 60px;
    padding-bottom: 80px;
    position: relative;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    padding-right: 60px;

    @include w1024 {
      display: none;
    }

    &--city {
      font-size: 13px;
      margin-right: 32px;
    }

    &--phone {
      font-size: 19px;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 15vh;
    flex: 1;

    .text-pinyon {
      margin-bottom: 40px;

      @include w1440 {
        margin-bottom: 20px;
      }

      @include w1024 {
        margin-bottom: 5px;
      }
    }

    h1 {
      margin-bottom: 60px;

      @include w1440 {
        margin-bottom: 30px;
      }
    }
  }

  .btn-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(calc(-50% + 0.5px), -50%);
  }
}