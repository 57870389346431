.screen-services {
  &__main {
    background: $background-blue-2;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 120px 0 90px;

    @include w1440 {
      padding: 60px 0 45px;
    }

    @include w1024 {
      padding: 30px 0 0;
      flex-direction: column;

      &:before {
        display: none;
      }

      .col {
        flex: 1;
        width: 100%;
      }
    }

    &.line-center {
      &:before {
        z-index: 0;
        left: 50%;
      }
    }
  }

  &__request {
    padding: 0 20px 100px 120px;
    height: 550px;
    border-bottom: 1px solid $white-low;
    display: flex;
    flex-direction: column;

    @include w1440 {
      padding: 0 20px 50px 60px;
      height: 350px;
    }

    @include w1024 {
      padding: 0 30px 40px;
      flex-direction: row;
      align-items: center;
      height: auto;
      border-bottom: 1px solid $white-low;
    }

    h2 {
      margin: 0 0 40px 0;
      
      @media (min-width: 1201px) {
        font-size: 36px;
        line-height: 50px;  
      }

      @include w1440 {
        margin: 0 0 20px 0;
      }

      @include w1024 {
        margin: 0 0 10px 0;
      }
    }

    .btn__wrapper {
      margin-top: auto;

      @include w1024 {
        margin-left: auto;
        margin-top: 0;
      }

      @include w480 {
        display: none;
      }
    }
  }

  &-item {
    padding: 30px 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }

    @include w1440 {
      padding: 30px 0;
      margin-bottom: 15px;
    }

    @include w1024 {
      border-bottom: 1px solid $white-low;
      margin-bottom: 0px;
    }

    &--main {
      position: relative;
      z-index: 2;
    }

    &__header {
      padding: 0 65px 0 90px;
      font-size: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;

      @include w1440 {
        padding: 0 30px;
        font-size: 16px;
      }

      &--icon {
        margin-right: 45px;

        @include w1440 {
          margin-right: 20px;
        }
      }

      &--text {
        flex: 1;
      }

      &--cross {
        margin-left: auto;
        position: relative;
        height: 33px;
        width: 33px;

        @include w1440 {
          height: 20px;
          width: 20px;
        }

        &::before, &::after {
          content: '';
          display: block;
          width: 0.5px;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: $white-text;
        }
        &::before {
          transform: translate(-50%, -50%) rotate(90deg);
        }
        &::after {
          transform: translate(-50%, -50%);
        }
      }
    }

    &__content {
      display: none;
      font-size: 17px;
      line-height: 28px;
      padding: 20px 135px 20px 220px;

      @include w1440 {
        padding: 20px 30px;
      }

      p {
        margin-bottom: 45px;
        font-size: 17px;

        @include w1440 {
          font-size: 15px;
          margin-bottom: 30px;
        }

        @include w1024 {
          font-size: 15px;
          margin-bottom: 15px;
        }
      }
    }

    &.active {
      background: url('../images/services/services-1.png') no-repeat center center;
      background-size: cover;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -1px;
        background: $blue-opacity;
        display: block;
        z-index: 1;
      }

      .screen-services-item__header {
        color: $gold;
        .screen-services-item__header--cross {
          &::before, &::after {
            background-color: $gold;
          }
          &::after {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
      .screen-services-item__content {
        display: block;
      }
    }
  }
}